import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  Marker,
  DirectionsRenderer,
  InfoWindow,
} from "react-google-maps";
import dayjs from "dayjs";
import Loading from "./Loading";
import Firebase from "../lib/Firebase";

const ServiceMap = ({trip}) => {
  const mapRef = useRef(null);
  const [path, setPath] = useState([]);
  const [directions, setDirections] = useState(null);
  const [stops, setStops] = useState([]);
  const [center, setCenter] = useState({lat: 37.44, lng: -106.59});
  const timestamp = path[path.length - 1]?.timestamp;
  const geocodeTimestamp = dayjs(new Date()).diff(timestamp, "m");
  const [shuttleData] = Firebase.getShuttleGeolocationData(trip?.shuttle.id);

  useEffect(() => {
    if (trip?.pickup_location || trip?.dropoff_location) {
      setStops([trip.pickup_location, trip.dropoff_location]);
    }
  }, [trip.pickup_location, trip.dropoff_location]);

  useEffect(() => {
    if (shuttleData) {
        const { latitude: lat, longitude: lng, timestamp: tmp } = shuttleData;
        setPath((p) => [...p, {lat, lng, timestamp: tmp ? tmp.toDate() : new Date()}]);
    }
  }, [shuttleData]);

  useEffect(() => {
    if (stops.length <= 0) {
      return;
    }

    const directionsService = new window.google.maps.DirectionsService();

    directionsService.route({
      origin: {
        lat: stops[0].latitude,
        lng: stops[0].longitude
      },
      destination: {
        lat: stops[1].latitude,
        lng: stops[1].longitude
      },
      travelMode: window.google.maps.TravelMode.DRIVING
    }, (result, status) => {
      if (status === window.google.maps.DirectionsStatus.OK) {
        setDirections(result);
      }
    });

    setCenter({
      lat: stops[0]?.latitude,
      lng: stops[0]?.longitude,
    });
  }, [stops])

  useEffect(() => {
    if (path?.length > 1) {
      const point1 = path[path.length - 1];
      const point2 = path[path.length - 2];
  
      const point1LatLng = new window.google.maps.LatLng(point1.lat, point1.lng);
      const point2LatLng = new window.google.maps.LatLng(point2.lat, point2.lng);
  
      const angle = window.google.maps.geometry.spherical.computeHeading(
        point1LatLng,
        point2LatLng
      );
      const actualAngle = angle + 90;
  
      const markerUrl = process.env.PUBLIC_URL + "images/icon_van2x.png";
      const marker = document.querySelector(`[src="${markerUrl}"]`);
  
      if (marker) {
        // when it hasn't loaded, it's null
        marker.style.transform = `rotate(${actualAngle}deg)`;
        marker.style.transition = "transform 0.5s";
      }
    }
  }, [path]);

  useEffect(() => {
    const bounds = new window.google.maps.LatLngBounds();

    if (path.length > 0) {
      const {lat, lng} = path[path.length - 1];
      const shuttleLocation = new window.google.maps.LatLng(lat, lng);

      bounds.extend(shuttleLocation);
    }

    if (stops.length > 0) {
      stops.map((stop) => {
        const location = new window.google.maps.LatLng(stop.latitude, stop.longitude);
        return bounds.extend(location);
      });
    }

    setTimeout(() => {
      if (mapRef.current) {
        mapRef.current.fitBounds(bounds);
      }
    }, 500);
  }, [path, stops]);

  const icon1 = {
    url: process.env.PUBLIC_URL + "images/icon_van2x.png",
    scaledSize: new window.google.maps.Size(40, 40),
    anchor: new window.google.maps.Point(15, 15),
    scale: 0.7
  };
  const pickupIcon = new window.google.maps.MarkerImage(
    process.env.PUBLIC_URL + "images/pickup_pin.png",
    null,
    null,
    null,
    new window.google.maps.Size(48, 48)
  );
  const dropoffIcon = new window.google.maps.MarkerImage(
    process.env.PUBLIC_URL + "images/dropoff_pin.png",
    null,
    null,
    null,
    new window.google.maps.Size(48, 60)
  );

  if (directions === null) {
    return <Loading />;
  }

  return (
    <>
      <GoogleMap
        ref={mapRef}
        defaultZoom={5}
        zoom={stops.length <= 0 ? 5 : 12}
        defaultCenter={center}
        center={center}
        options={{
          zoomControl: true,
          mapTypeControl: false,
          fullscreenControl: false,
          scaleControl: false,
          streetViewControl: false,
          controlSize: 27,
          trackViewChanges: false
        }}
      >
        <DirectionsRenderer
          directions={directions}
          options={{
            polylineOptions: {
              strokeColor: 'black',
              strokeOpacity: 1,
              strokeWeight: 3,
              clickable: false,
              draggable: false,
              editable: false
            },
            suppressMarkers: true,
            preserveViewport: true
          }}
        />
        {
          stops.map((stop, idx) => (
            <Marker
              key={idx}
              position={{
                lat: stop.latitude,
                lng: stop.longitude
              }}
              // title={stop.type}
              icon={idx === 0 ? pickupIcon : dropoffIcon}
            />
          ))
        }
        { path.length > 0 &&
            <Marker
              icon={icon1}
              position={path[path.length - 1]}
              style={{height: 40, width: 40}}
            >
                <InfoWindow>
                  <span style={{color: "#4A525F"}}>
                    <div>{trip?.shuttle?.license_plate}</div>
                    <div>
                      { geocodeTimestamp > 1 ?
                        `${geocodeTimestamp} minute${geocodeTimestamp > 1? "s" : ""} ago`
                        : "Real-time location"
                      }
                    </div>
                  </span>
                </InfoWindow>
            </Marker>
        }
      </GoogleMap>
    </>
  );
}

export default ServiceMap;