export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const APIKEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const AUTHDOMAIN = process.env.REACT_APP_AUTHDOMAIN;
export const DATABASEURL = process.env.REACT_APP_DATABASEURL;
export const PROJECTID = process.env.REACT_APP_PROJECTID;
export const STORAGEBUCKET = process.env.REACT_APP_STORAGEBUCKET;
export const MESSAGINGSENDERID = process.env.REACT_APP_MESSAGINGSENDERID;
export const APPID = process.env.REACT_APP_APPID;
export const MEASUREMENTID = process.env.REACT_APP_MEASUREMENTID;
export const DUET_MARKETING_URL = process.env.REACT_APP_DUET_MARKETING_URL;
export const REACT_APP_APPCHECK_SITE_KEY = process.env.REACT_APP_APPCHECK_SITE_KEY;