import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Space, notification, Checkbox } from 'antd';
import Icon from '@ant-design/icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ReactComponent as timePickerSvg } from '../common/images/time_picker_icon.svg';
import { ReactComponent as timeColonSvg } from '../common/images/time_colon_icon.svg';
import { isEmpty } from 'lodash';
import Modal from './Modal';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const AMPMRadio = ({ ampmValue, value, label, name, onChange, style }) => {
    return (
        <label style={{ margin: 0, height: "100%", cursor: "pointer", ...style }}>
            <input
                name={name}
                type="radio"
                style={{ display: "none" }}
                value={value}
                onChange={onChange}
            />
            <div
                className="ampm-component"
                style={{
                    background: ampmValue === value ? "#FFFFFF" : "none",
                    color: ampmValue === value ? "#118BF0" : "#9398A0",
                    fontWeight: ampmValue === value ? 700 : 400,
                    boxShadow: ampmValue === value ? "0px 4px 10px rgba(0, 0, 0, 0.05)" : "none",
                }}
            >
            {label}
            </div>
        </label>
    );
}

const TimeInput = ({label, value, ...props}) => {
    return(
        <div style={{display: "inline-grid"}}>
            <Input
                {...props}
                value={value}
                className="time-input-component"
                onKeyDown={(event) => {
                    if (!/[0-9]/.test(event.key) && event.key !== "Backspace") {
                        event.preventDefault();
                    }
                }}
                maxLength={2}
            />
            <label className="time-input-label">
                {label}
            </label>
        </div>
    );
}

const TimeInputModal = ({
    isModalOpen,
    handleOk,
    handleCancel,
    setTimeInfo,
    selectedTime,
    selectedDate,
    timezone
}) => {
    const [timeType, setTimeType] = useState("AM");
    const [hour, setHour] = useState(null);
    const [minute, setMinute] = useState(null);
    const [isWillCall, setIsWillCall] = useState(false);

    const handleClose = () => {
        setTimeType("AM");
        setHour(null);
        setMinute(null);
        setIsWillCall(false);
        handleCancel();
    };

    useEffect(() => {
        if (isModalOpen && !isEmpty(selectedTime)) {
            if (selectedTime === "Will call") {
                setIsWillCall(true);
            } else {
                const [time, type] = selectedTime.split(" ");
                const [am, pm] = time.split(":");
    
                setTimeType(type);
                setHour(Number(am));
                setMinute(Number(pm));
            }
        }
    }, [isModalOpen, selectedTime]);

    return (
        <Modal
            isOpen={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <h2
                style={{
                    margin: 0,
                    fontWeight: 700,
                    fontSize: "22px",
                    lineHeight: "26px",
                    color: "#4A525F"
                }}
                >
                Enter Time
            </h2>
            <div
                style={{
                    display: "inline-flex",
                    marginTop: "40px"
                }}
            >
                <div style={{display: "inline-flex"}}>
                    <TimeInput
                        label="Hour"
                        value={hour}
                        onChange={(e) => {
                            const {value} = e.target;

                            if (value <= 12) {
                                setHour(value);
                            }
                        }}
                        disabled={isWillCall}
                    />
                    <Icon style={{fontSize: "1.5rem"}} component={timeColonSvg}/>
                    <TimeInput
                        label="Minute"
                        value={minute}
                        onChange={(e) => {
                            const {value} = e.target;

                            if (value <= 59) {
                                setMinute(value);
                            }
                        }}
                        disabled={isWillCall}
                    />
                </div>
                <div className="ampm-container">
                    <AMPMRadio
                        name="ampm"
                        ampmValue={timeType}
                        label="AM"
                        value="AM"
                        onChange={(e) => {
                            setTimeType(e.target.value);
                        }}
                    />
                    <AMPMRadio
                        name="ampm"
                        ampmValue={timeType}
                        label="PM"
                        value="PM"
                        onChange={(e) => {
                            setTimeType(e.target.value);
                        }}
                    />
                </div>
            </div>
            <Checkbox
                checked={isWillCall}
                style={{
                    marginTop: 30,
                    color: "#4A525F",
                    fontSize: 16,
                    fontWeight: 400,
                }}
                onChange={(e) => {
                    if (e.target.checked) {
                        setTimeType("AM");
                        setHour(null);
                        setMinute(null);
                    }

                    setIsWillCall(e.target.checked);
                }}
            >
                Will call, i.e., pickup time is not available.
            </Checkbox>
            <div className="modal-button-container">
                <Space>
                    <Button
                        style={{
                            borderRadius: 3,
                            fontWeight: 600,
                            backgroundColor: "#D7DCE5",
                            color: "#4A525F"
                        }}
                        size="large"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{
                            backgroundColor: "#118BF0",
                            color: "#FFFFFF",
                            borderRadius: 3,
                            fontWeight: 600,
                        }}
                        size="large"
                        onClick={() => {
                            try {
                                // check will call first
                                if (isWillCall) {
                                    setTimeInfo("Will call");
                                    handleClose();

                                    return;
                                }

                                const selectedTimeString = `${hour}:${minute} ${timeType}`;
                                const formattedTime = dayjs(selectedTimeString, "h:mm A");
                                const currentDay = dayjs(new Date()).tz(timezone);
                                const selectedDateFormat = selectedDate && dayjs(selectedDate).format("MM/DD/YYYY");
                                const convertedSelectedTime = selectedDate && dayjs.tz(`${selectedDateFormat} ${selectedTimeString}`, "MM/DD/YYYY h:mm A", timezone);

                                if (currentDay.isAfter(convertedSelectedTime)) {
                                    notification.warning({
                                        message: "New Reservation Alert",
                                        description: "The selected time is in the past.",
                                });
                                } else if (formattedTime.isValid()) {
                                    setTimeInfo(formattedTime.format("h:mm A"));
                                    handleClose();
                                } else {
                                    notification.warning({
                                        message: "New Reservation Alert",
                                        description: "Invalid time format.",
                                    });
                                }
                            } catch(err) {
                                notification.warning({
                                    message: "New Reservation Alert",
                                    description: "Invalid time format.",
                                });
                            } 
                        }}
                    >
                        Save
                    </Button>
                </Space>
            </div>
        </Modal>
    );
};

const FormTimePicker = ({
    name,
    rules,
    label,
    setValue,
    timezone,
    selectedDate,
    selectedTime,
    isReset,
    setIsReset,
    style,
    inputStyle,
    disabled = false,
    required = false,
}) => {
    const [timeInfo, setTimeInfo] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleModalOk = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (timeInfo) {
            setValue(timeInfo);
        }
    }, [timeInfo, setValue])

    // unset value on disabled
    useEffect(() => {
        setTimeInfo(null);
    }, [disabled, setTimeInfo])

    useEffect(() => {
        if (isReset) {
            setTimeInfo(null);
            setValue(null);
            setIsReset(false);
        }
    }, [isReset, setIsReset, setValue]);

    return (
        <div style={{width: "100%", ...style}}>
            <span className="form-title">
                {label}{required ? " *" : ""}
            </span>
            <TimeInputModal
                timezone={timezone}
                selectedDate={selectedDate && selectedDate()}
                selectedTime={selectedTime && selectedTime()}
                setTimeInfo={setTimeInfo}
                isModalOpen={isModalOpen}
                handleOk={handleModalOk}
                handleCancel={() => {
                    setIsModalOpen(false);
                }}
            />
            <Form.Item
                name={name}
                rules={rules}
                style={{marginBottom: 8}}
                onClick={() => {
                    if (!disabled) {
                        setIsModalOpen(true);
                    }
                }}
            >
                <Input
                    className="form-time-picker"
                    value={timeInfo}
                    size="large"
                    style={{
                        width: "100%",
                        borderRadius: 5,
                        ...inputStyle,
                    }}
                    suffix={<Icon component={timePickerSvg} />}
                    disabled={disabled}
                    readOnly
                />
            </Form.Item>
        </div>
    )
};
  
export default FormTimePicker;