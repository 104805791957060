import React from 'react';
import { Form, Select } from 'antd';
import { lowerCase } from 'lodash';

const FormSelect = ({
    name,
    rules = [],
    label,
    value,
    defaultValue,
    style,
    inputStyle,
    options = [],
    disabled = false,
    required = false,
}) => {
    return (
        <div style={{width: "100%", ...style}}>
            <span className="form-title">
                {label}{required ? " *" : ""}
            </span>
            <Form.Item
                name={name}
                style={{marginBottom: 8}}
                initialValue={defaultValue}
                rules={rules.concat({ required: required && !disabled, message: `Please select ${lowerCase(label)}.`})}
            >
                <Select
                    className="form-select"
                    listHeight={320}
                    placeholder={null}
                    value={value}
                    size="large"
                    style={inputStyle}
                    options={options}
                    disabled={disabled}
                />
            </Form.Item>
        </div>
    )
};
  
export default FormSelect;