import React, { createRef, useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete';
import { lowerCase } from 'lodash';

const FormPlacesAutocomplete = ({
    name,
    rules,
    label,
    style,
    inputStyle,
    setValue,
    isReset,
    setIsReset,
    required = false,
}) => {
    const addressLineInputRef = createRef();
    const [addressLine, setAddressLine] = useState("");

    const onAddressChange = async (value, placeId = null) => {
        let add = value;
    
        if (placeId) {
          const completeLoc = await geocodeByPlaceId(placeId);
          const zip_code = completeLoc[0].address_components.find(
            addr => addr.types[0] === "postal_code"
          )?.short_name;
          if (zip_code) {
            add = `${value}, ${zip_code}`;
          }
        }

        setAddressLine(add);
        setValue(add);
    };

    useEffect(() => {
        if (isReset) {
            setAddressLine("");
            setIsReset(false);
        }
    }, [isReset, setIsReset]);

    return (
        <div style={{width: "100%", ...style}}>
            <span className="form-title">
                {label}{required ? " *" : ""}
            </span>
            <Form.Item
                name={name}
                rules={rules ? rules : [{ required, message: `Please input ${lowerCase(label)}`}]}
                style={{marginBottom: 8}}
            >
                <PlacesAutocomplete
                    value={addressLine}
                    onChange={onAddressChange}
                    onSelect={onAddressChange}
                    style={{
                        width: "100%",
                        borderRadius: 5,
                        color: "#4A525F",
                        ...inputStyle,
                    }}
                >
                    {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps
                    }) => (
                        <div
                            style={{
                            position: "relative",
                            width: "100%"
                            }}
                        >
                            <Input
                                ref={addressLineInputRef}
                                {...getInputProps({
                                    // className: "normal form-control"
                                })}
                                value={addressLine}
                                size="large"
                                style={{
                                    width: "100%",
                                    borderRadius: 5,
                                    color: "#4A525F",
                                    fontWeight: 500,
                                    ...inputStyle,
                                }}
                            />
                            <div
                                style={{
                                    position: "absolute",
                                    zIndex: 99999,
                                    width: "100%"
                                }}
                            >
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                                const style = suggestion.active
                                ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                    padding: 5
                                    }
                                : {
                                    backgroundColor: "rgb(236 232 232)",
                                    cursor: "pointer",
                                    padding: 5
                                    };
                                return (
                                <div
                                    id="address"
                                    {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style
                                    })}
                                    key={suggestion.description}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                                );
                            })}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
            </Form.Item>
        </div>
    )
};
  
export default FormPlacesAutocomplete;