import React from 'react';
import { Input } from 'antd';

const InputComponent = ({label, value, style, inputStyle}) => {
    return (
        <div style={{width: "100%", ...style}}>
            <span style={{color: "#4A525F", fontWeight: 500}}>{label}:</span>
            <Input
                value={value}
                style={{
                    width: "100%",
                    background: "rgba(215, 220, 229, 0.2)",
                    borderRadius: 5,
                    color: "#4A525F",
                    ...inputStyle,
                }}
            />
        </div>
    )
};
  
export default InputComponent;