import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import RiderWebVehicleTracking from "./pages/RiderWebVehicleTracking";
import ReservationPortal from './pages/ReservationPortal';
import NotFound from "./pages/NotFound";
import Firebase from './lib/Firebase';
import { DUET_MARKETING_URL } from './common/config';
import './styles.css';
import Loading from './components/Loading';

export default function App() {
  const [logo, setLogo] = useState(null);
  const [website, setWebsite] = useState(DUET_MARKETING_URL);
  const [isLogInSuccess, setIsLogInSuccess] = useState(false);

  useEffect(() => {
    // sign in user automatically
    (async () => {
      const result = await Firebase.signInAnonymously();
      setIsLogInSuccess(result.success);
    })();

    if (window.location.pathname === "/") {
      window.location.replace(DUET_MARKETING_URL);
    }
  }, [])

  return (
    <BrowserRouter>
      <Layout className='App' logo={logo} website={website}>
        { isLogInSuccess ?
          <Routes>
              <Route path="/:tripId" element={<RiderWebVehicleTracking setLogo={setLogo}/>}/>
              <Route path="/reservation/:pathId"
                element={<ReservationPortal setLogo={setLogo} setWebsite={setWebsite}/>}/>
              <Route path="*" element={<NotFound />} />
          </Routes>
          : <Loading />
        }
      </Layout>
    </BrowserRouter>
  );
}