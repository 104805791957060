import React from 'react';
import { Layout } from 'antd';
import Footer from './Footer';
import Header from './Header';

const { Content } = Layout;

const LayoutComponent = (props) => {
    return (
        <Layout className="layout">
            <Header {...props}/>
            <Content>
                {props.children}
            </Content>
            <Footer />
        </Layout>
    )
};
  
export default LayoutComponent;