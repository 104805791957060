import React from 'react';
import { Layout } from 'antd';
import { DUET_MARKETING_URL } from '../../common/config';

const { Footer } = Layout;

const FooterComponent = () => {
    return (
        <Footer className="footer">
            <span style={{color: "#9398A0", fontSize: 14}}>
                Powered by
            </span>
            <div style={{margin: 15}}/>
            <a href={DUET_MARKETING_URL}>
                <img
                    src={`${process.env.PUBLIC_URL}/images/logo.png`}
                    alt="duet_logo"
                    style={{height: 30}}
                />
            </a>
        </Footer>
    )
};
  
export default FooterComponent;