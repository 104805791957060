import React from 'react';
import { Card, Modal } from 'antd';

const ModalComponent = ({
    title,
    isOpen = false,
    children,
    ...props
}) => {
    return (
        <Modal
            open={isOpen}
            closeIcon={false}
            footer={null}
            width={430}
            centered
            {...props}
        >
            <Card className="modal-component">
                {children}
            </Card>
        </Modal>
    )
};

export default ModalComponent;