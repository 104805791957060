import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const loadingIcon = (
  <LoadingOutlined
    style={{
      fontSize: 32,
    }}
    spin
  />
);

const Loading = (props) => <Spin indicator={loadingIcon} {...props}/>;

export default Loading;