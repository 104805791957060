import React from 'react';
import { Layout } from 'antd';

const { Header } = Layout;

const HeaderComponent = ({logo, website}) => {
    return (
        <Header className='header'>
            { logo &&
                <img
                    className="header-logo"
                    src={logo}
                    alt="logo"
                    onClick={() => {
                        let url = website;

                        if (!/^https?:\/\//i.test(url)) {
                            url = 'https://' + url;
                        }

                        window.location.href = url;
                    }}
                />
            }
        </Header>
    )
};
  
export default HeaderComponent;