import axios from "axios";
import loadGoogleMapsApi from "load-google-maps-api";
import "dotenv";

export async function getLatLngFromAddress(address) {
  const resp = await axios({
    method: "get",
    url: "https://maps.googleapis.com/maps/api/geocode/json",
    params: {
      address,
      // key: GoogleMapAPIKEY
      key: process.env.REACT_APP_GOOGLE_API_KEY
    }
  });
  // console.log(JSON.stringify(resp));
  let loc = { lat: 0, lng: 0 };

  if (resp.data.results.length) loc = resp.data.results[0].geometry.location;

  return { lat: loc.lat, lng: loc.lng };
}

export async function getTimezoneFromAddress(location) {
  const resp = await axios({
    method: "get",
    url: "https://maps.googleapis.com/maps/api/timezone/json",
    params: {
      location,
      timestamp: 1458000000,
      // key: GoogleMapAPIKEY
      key: process.env.REACT_APP_GOOGLE_API_KEY
    }
  });
  // console.log("getTimezoneFromAddress = ", JSON.stringify(resp));
  return resp.data.timeZoneId;
}

export async function getDistanceBetweenTwoLocation(origin, destination) {
  return new Promise((resolve, reject) => {
    // loadGoogleMapsApi({ key: GoogleMapAPIKEY })
    loadGoogleMapsApi({ key: process.env.REACT_APP_GOOGLE_API_KEY })
      .then(googleMaps => {
        const matrixcls = new googleMaps.DistanceMatrixService();
        matrixcls.getDistanceMatrix(
          {
            // origins: [new googleMaps.LatLng(origin.lat, origin.lng)],
            // destinations: [new googleMaps.LatLng(destination.lat, origin.lng)],
            origins: [origin], // based on address
            destinations: [destination], // based on address
            travelMode: googleMaps.TravelMode.DRIVING
          },
          response => {
            // console.log(status);
            // console.log(JSON.stringify(response));
            resolve(response);
          }
        );
      })
      .catch(error => {
        reject(error);
        console.error(error);
      });
  });
}

export async function getRoutesBetweenLocations(origin, destination) {
  const resp = await axios({
    method: "get",
    url: "https://maps.googleapis.com/maps/api/directions/json",
    params: {
      origin,
      destination,
      // key: GoogleMapAPIKEY
      key: process.env.REACT_APP_GOOGLE_API_KEY
    },
    headers: {
      crossDomain: true
    }
  });
  // console.log(JSON.stringify(resp));
  return resp.data.routes;
}

export default { getLatLngFromAddress };
