import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';
import { signInAnonymously, getAuth } from 'firebase/auth';
import { getFirestore, collection, doc, query, where, getDoc } from "firebase/firestore";
import { getToken, initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { useCollectionDataOnce, useDocumentData } from 'react-firebase-hooks/firestore';
import * as config from '../common/config';
import * as collections from '../common/enums/firebase-collections';

class Firebase {
    constructor() {
        const firebaseConfig = {
            apiKey: config.APIKEY,
            authDomain: config.AUTHDOMAIN,
            databaseURL: config.DATABASEURL,
            projectId: config.PROJECTID,
            storageBucket: config.STORAGEBUCKET,
            messagingSenderId: config.MESSAGINGSENDERID,
            appId: config.APPID,
            measurementId: config.MEASUREMENTID,
        }

        // Initialize Firebase
        const app = initializeApp(firebaseConfig);
        this.appCheck = initializeAppCheck(app, {
            provider: new ReCaptchaV3Provider(config.REACT_APP_APPCHECK_SITE_KEY),
            
            // Optional argument. If true, the SDK automatically refreshes App Check
            // tokens as needed.
            isTokenAutoRefreshEnabled: true
        });

        this.auth = getAuth(app);
        this.database = getDatabase(app);
        this.firestore = getFirestore(app);
    }

    // sign in app anonymously
    signInAnonymously = async () => {
        const result = {
            success: false,
            error: false,
        };

        try {
            await signInAnonymously(this.auth);
            result.success = true;
        } catch(err) {
            console.error(err);
            result.error = true;
        }

        return result;
    }

    getAppCheckToken = async () => {
        let appCheckToken = null;

        try {
           const appCheckTokenResponse = await getToken(this.appCheck, false);

           appCheckToken = appCheckTokenResponse.token;
        } catch (err) {
            console.log(err);
        }

        return appCheckToken;
    }

    readData = (field, id, setData) => {
        const getData = ref(this.database, `${field}/` + id);

        onValue(getData, (snapshot) => {
            const data = snapshot.val();
            setData(data || {notFound: true});
        }, (err) => {
            setData({error: true});
        });

        return this.onValue;
    }

    /**
     * Get trip data on ServiceMap collection
     * @param {String} id
     * 
     * @return {Array} [data, loading, error]
     */
    getTripData = (id) => {
        const dataRef = collection(this.firestore, collections.SERVICE_MAP);
        const result = useDocumentData(doc(dataRef, id));

        return result;
    }

    /**
     * Get fleet data on Fleet collection by ID
     * @param {String} id
     * 
     * @return {Object}
     */
    getFleetDataById = async (id) => {
        try {
            const dataRef = collection(this.firestore, collections.FLEET);
            const result = await getDoc(doc(dataRef, id));
    
            return result.data();
        } catch(err) {
            console.log(err);
            return null;
        }
    }

    /**
     * Get Fleet data on Fleet collection
     * @param {String} id
     * 
     * @return {Array} [data, loading, error]
     */
    getFleetData = (fleetName, portalId, facilityId) => {
        const formattedFleetName = fleetName.replace(/-/g, " ");
        const dataRef = collection(this.firestore, collections.FLEET);
        const fleetQuery = query(dataRef, where("name", "==", formattedFleetName));
        const result = useCollectionDataOnce(fleetQuery);
        const [fleetList = []] = result;

        let fleetDataObj = {};

        for (const fleetObj of fleetList) {
            const { programs, insurance } = fleetObj;

            for (const programObj of programs) {
                if (programObj.reservation_portal_id === portalId) {
                    const adaSupport = programObj.ada_support;

                    fleetObj.program = programObj;
                    fleetObj.mobility_assistance = (fleetObj.mobility_assistance)
                        .filter(({type}) => adaSupport.includes(type));
                    fleetDataObj = {...fleetObj};

                    break;
                }
            }

            if (facilityId) {
                for (const insuranceObj of Object.values(insurance)) {
                    if (insuranceObj.facility_portal_id === facilityId) {
                        fleetDataObj.facility = insuranceObj;
    
                        break;
                    }
                }
            }
        }

        // if no facility found where facilityId is provided
        // then facility object should be empty
        if (facilityId && !fleetDataObj?.facility) {
            fleetDataObj = {};
        }

        delete fleetDataObj.insurance;
        delete fleetDataObj.programs;
        result[0] = fleetDataObj;

        return result;
    }

    /**
     * Get shuttle geolocation data on ShuttleGeolocation collection
     * @param {String} id
     * 
     * @return {Array} [data, loading, error]
     */
    getShuttleGeolocationData = (id) => {
        const dataRef = collection(this.firestore, collections.SHUTTLE_GEOLOCATION);
        const result = useDocumentData(doc(dataRef, id));

        return result;
    }
}

export default new Firebase();