import React from 'react';
import { Form, DatePicker } from 'antd';
import Icon from '@ant-design/icons';
import { ReactComponent as datePickerSvg } from '../common/images/date_picker_icon.svg';

const FormDatePicker = ({
    name,
    rules,
    label,
    value,
    defaultValue,
    style,
    inputStyle,
    onChange,
    disabledDate,
    disabled = false,
    required = false,
}) => {
    return (
        <div style={{width: "100%", ...style}}>
            <span className="form-title">
                {label}{required ? " *" : ""}
            </span>
            <Form.Item
                name={name}
                rules={rules}
                style={{marginBottom: 8}}
            >
                <DatePicker
                    className="form-date-picker"
                    defaultValue={defaultValue}
                    placeholder={null}
                    value={value}
                    size="large"
                    style={{
                        width: "100%",
                        borderRadius: 5,
                        ...inputStyle,
                    }}
                    onChange={onChange}
                    suffixIcon={<Icon component={datePickerSvg} />}
                    disabledDate={disabledDate} 
                    disabled={disabled}
                />
            </Form.Item>
        </div>
    )
};
  
export default FormDatePicker;