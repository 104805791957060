import React, { createRef, useState } from 'react';
import { Form, Input } from 'antd';
import { lowerCase } from 'lodash';

const { TextArea } = Input;

const defaultInputStyle = {
    width: "100%",
    borderRadius: 5,
    color: "#4A525F",
    fontWeight: 500,
};

const FormInput = ({
    name,
    rules = [],
    label,
    value,
    type = "input",
    style,
    inputStyle,
    setValue = () => {},
    disabled = false,
    required = false,
}) => {
    const inputRef = createRef();
    const [phoneValue, setPhoneValue] = useState(null);
    const [pressedKey, setPressedKey] = useState(null);

    return (
        <div style={{width: "100%", ...style}}>
            <span className="form-title">
                {label}{required ? " *" : ""}
            </span>
            <Form.Item
                name={name}
                style={{marginBottom: 8}}
                rules={rules.concat({ required: required && !disabled, message: `Please input ${lowerCase(label)}.`})}
            >
                { type === "text-area"
                    ? <TextArea
                        value={value}
                        size="large"
                        style={{
                            ...defaultInputStyle,
                            ...inputStyle,
                        }}
                        disabled={disabled}
                    />
                    : <Input
                        ref={inputRef}
                        value={type === "phone" ? phoneValue : value}
                        size="large"
                        style={{
                            ...defaultInputStyle,
                            ...inputStyle,
                        }}
                        onKeyDown={(event) => {
                            if (type === "phone"
                                && !/[0-9]/.test(event.key)
                                && event.key !== "Backspace"
                                && event.key !== "ArrowRight"
                                && event.key !== "ArrowLeft"
                                && event.key !== "ArrowUp"
                                && event.key !== "ArrowDown"
                            ) {
                                event.preventDefault();
                            }

                            setPressedKey(event.key);
                        }}
                        onChange={() => {
                            if (type === "phone") {
                                const inputValue = inputRef.current.input.value;
                                const extractNumber = inputValue.replace(/\D/g, "");
                                const first3String = extractNumber.substring(0, 3);
                                const second3String = extractNumber.substring(3, 6);
                                const remString = extractNumber.substring(6);
                                let formattedValue = inputValue
                                    ? `${first3String ? `(${first3String})` : ""}${second3String ? ` ${second3String}` : ""}${remString ? `-${remString}` : ""}`
                                    : null;
                    
                                if (inputValue.length <= 4 && pressedKey === "Backspace") {
                                    formattedValue = `(${first3String.slice(0, -1)})`;

                                    if (first3String.length === 1 || phoneValue === "()") {
                                        formattedValue = null;
                                    }
                                }

                                setPhoneValue(formattedValue);
                                setValue(formattedValue);
                            }
                        }}
                        maxLength={type === "phone" ? 14 : null}
                        disabled={disabled}
                    />
                }
            </Form.Item>
        </div>
    )
};
  
export default FormInput;